import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  ThemeProvider,
  IconButton,
} from "@mui/material";
import { QueryStats, Storage} from "@mui/icons-material";
import theme from "../../Utils/themes";
import CallMadeIcon from "@mui/icons-material/CallMade";

const services = [
  {
    icon: QueryStats,
    title: "Purchase Management",
    description:
      "Our Purchase Management module features AI and machine learning-powered models for advanced commodity forecasting.",
    link: "/explore/purchase-management",
  },
  {
    icon: Storage,
    title: "Production Planning",
    description:
      "Our Production Planning module leverages AI-driven tools to optimize your manufacturing processes.",
    link: "/explore/production-planning",
  },
];

const ServiceCard = ({ service }) => (
  <Card
    sx={{
      width: "100vw",
      maxWidth: { xs: 500, sm: 500 },
      height: '35vh',
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#074668",
      borderRadius: 3,
      mx: "auto",
      justifyContent: "center",
    }}
  >
    <CardContent
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 2,
        margin: 3,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <service.icon
            fontSize="large"
            sx={{ color: "whitesmoke", fontSize: "3rem" }}
          />
          <Box flexGrow={1} />
          <IconButton href={service.link} sx={{ color: "whitesmoke" }}>
            <CallMadeIcon />
          </IconButton>
        </Box>

        <Typography
          variant="h4"
          fontWeight="bold"
          color="whitesmoke"
          sx={{ mt: 1, mb: 1.5, textAlign: "left" }}
        >
          {service.title}
        </Typography>
        <Typography
          variant="body1"
          color="whitesmoke"
          sx={{ mb: 1, textAlign: "left" }}
        >
          {service.description}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

const ServicesList = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: "60vh", padding: 5}}>
        <Grid container spacing={2} sx={{ padding: 2}}>
          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
              textAlign: "center",
              paddingX: { xs: 2, sm: 0 },

            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color="whitesmoke"
              sx={{
                display: "inline-block",
                width: { xs: "100%", sm: "auto" },
                wordWrap: "break-word",
                textAlign: "center",
              }}
            >
              Efficient and Integrated
              <br />
              Manufacturing Services
            </Typography>
          </Grid>

          {/* Service Cards */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center", // Ensure this applies on all screen sizes
              alignItems: "stretch",
            }}
          >
            {services.map((service, index) => (
              <Grid
                item
                xs={12}
                md={6}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "left",
                  padding: 0,
                  mb: { xs: 2, md: 0 },
                  height: "100%",
                }}
              >
                <ServiceCard service={service} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default ServicesList;
