import React from 'react';
import { Box, Typography, ThemeProvider } from '@mui/material';
import theme  from '../Utils/themes';

const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
    <Box 
      sx={{ 
        maxWidth: '80%', 
        margin: 'auto',
        marginTop:'2%', 
        padding: '20px', 
        backgroundColor: '#f9f9f9', 
        borderRadius: '8px', 
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', 
        height: '80vh', 
        overflowY: 'auto',
        textAlign: 'left'
      }}
    >
      <Box 
        sx={{
          backgroundColor: '#063954',
          color: 'white',
          padding: '10px',
          borderRadius: '8px 8px 8px 8px', // Rounded corners on top
          textAlign: 'center'
        }}
      >
        <Typography variant="h5" gutterBottom><strong>Privacy Policy</strong></Typography>
      </Box>
      <Box sx={{marginTop:'1%'}}>
        <Typography paragraph><strong>Last Updated: 21/08/2024</strong></Typography>
      </Box>

      <Typography variant="h6" gutterBottom><strong>1. Introduction</strong></Typography>
      <Typography paragraph>
        Applied Bell Curve ("we," "our," or "us") is committed to protecting the privacy of our customers and users of our services. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our Software as a Service (SaaS) tools for production optimization and purchase planning.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>2. Information We Collect</strong></Typography>
      <Typography paragraph>
        <strong>Personal Information:</strong> We may collect personal information such as your name, email address, company name, and payment information when you register for and use our services.
      </Typography>
      <Typography paragraph>
        <strong>Usage Data:</strong> We collect information about your use of our services, including but not limited to, system logs, IP addresses, browser type, operating system, and activity on our platform.
      </Typography>
      <Typography paragraph>
        <strong>Customer Data:</strong> We may collect data that you provide to us or that is generated through your use of our services for production optimization and purchase planning.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>3. How We Use Your Information</strong></Typography>
      <Typography paragraph>
        <strong>To Provide and Manage Services:</strong> We use your information to operate, maintain, and provide you with our services, including processing transactions, managing your account, and providing customer support.
      </Typography>
      <Typography paragraph>
        <strong>To Improve Our Services:</strong> We use your usage data to understand how our services are being used and to improve their functionality and user experience.
      </Typography>
      <Typography paragraph>
        <strong>To Communicate with You:</strong> We may use your contact information to send you important updates, newsletters, and marketing materials. You can opt-out of marketing communications at any time.
      </Typography>
      <Typography paragraph>
        <strong>To Ensure Security:</strong> We use your information to detect, prevent, and address technical issues, security breaches, and fraudulent activities.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>4. Data Security</strong></Typography>
      <Typography paragraph>
        We take the security of your data seriously and implement industry-standard measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of data transmission or storage is 100% secure.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>5. Data Sharing and Disclosure</strong></Typography>
      <Typography paragraph>
        We do not sell, trade, or share your personal or customer data with third parties, except as required by law or as necessary to provide our services (e.g., payment processing). Any third-party service providers we engage will be required to maintain the confidentiality and security of your information.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>6. Data Retention</strong></Typography>
      <Typography paragraph>
        We retain your personal and customer data only for as long as necessary to fulfill the purposes for which it was collected or to comply with legal obligations. Once the data is no longer needed, we will securely delete or anonymize it.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>7. Your Rights</strong></Typography>
      <Typography paragraph>
        You have the right to access, correct, or delete your personal information at any time. You may also object to or restrict the processing of your data. To exercise these rights, please contact us at admin@appliedbellcurve.com
      </Typography>

      <Typography variant="h6" gutterBottom><strong>8. Changes to This Privacy Policy</strong></Typography>
      <Typography paragraph>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top of the policy. We encourage you to review this policy periodically.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>9. Contact Us</strong></Typography>
      <Typography paragraph>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </Typography>
      <Typography paragraph>
        Applied Bell Curve <br/>
        admin@appliedbellcurve.com
      </Typography>
    </Box>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
