export const MODULE_DESCRIPTIONS = {
  forecasting: {
    name: "Forecasting",
    description: "Tool for commodities Forecasting",
    link:"https://forecast.appliedbellcurve.com",
  },
  optimising: {
    name: "Optimiser",
    description: "Tool for Optimising",
  },
  inventory_management: {
    name: "InventoryManagement",
    description: "Tool for Inventory Management",
    link:"https://shelf.appliedbellcurve.com",
  },
  trim_manager: {
    name:"Deckle Matching",
    description:"Tool for trim management",
    link:"https://trim.appliedbellcurve.com"
   
  }
  // Add other module descriptions here
};
