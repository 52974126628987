import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  ThemeProvider,
  useMediaQuery,
  Link as MuiLink,
} from "@mui/material";
import { Link } from "react-router-dom"; // React Router DOM Link for navigation
import theme from "../../Utils/themes";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import model from "../../Images/model.png";

const Footer = () => {
  const navigate = useNavigate();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Top section */}
      <Box
        sx={{
          paddingY: 3,
          overflowX: "hidden",
          minHeight: "400px",
        }}
      >
        <Grid container spacing={2} sx={{ mx: { xs: 2, sm: 10 } }}>
          {/* Left column */}
          <Grid
            item
            xs={10}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "auto",
            }}
          >
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                Empowering Top companies with Seamless Integration
              </Typography>

              <Typography variant="h6" sx={{ mb: 2 }}>
                Experience seamless connections with our innovative solutions,
                designed to effortlessly integrate with our existing systems,
                enhance productivity and drive your business towards greater
                success.
              </Typography>
            </Box>
          </Grid>

          {/* Right column */}
          {isMdUp && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 350,
                  mx: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={model}
                  alt="Model"
                  style={{ width: "100%", height: "100%", marginTop: 16 }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Middle section */}
      <Box
        sx={{
          backgroundColor: "#063954",
          minHeight: "300px",
          paddingY: 8,
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            mx: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={10}
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              backgroundColor: "#063954",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginTop: "50px",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", mb: 2, color: "white" }}
              >
                From Idea to Production in Days
              </Typography>

              <Typography variant="h6" sx={{ mb: 2, color: "white" }}>
                Accelerate your predictions with technology.
                <br />
                Reduce downtime and optimize costs. For more information.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#ADD8E6",
                  color: "#074668",
                  fontWeight: "bold",
                }}
                onClick={() => handleNavigate("/contactus")}
              >
                Work with us
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Footer section */}
      <Box
        component="footer"
        sx={{
          backgroundColor: "black",
          color: "white",
          width: "100%",
          py: 8,
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ maxWidth: "100%", m: "0 auto", textAlign: "center" }}
        >
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Applied Bell Curve
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Typography color="inherit">
                Our solutions make production faster and cheaper.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Company
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Link
                to="/aboutus"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "block",
                  marginBottom: "10px",
                }}
              >
                About Us
              </Link>
              <Link
                to="/explore"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "block",
                }}
              >
                Services
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Industries
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Typography color="inherit" sx={{ display: "block", mb: 1 }}>
                Textile Manufacturing
              </Typography>
              <Typography color="inherit" sx={{ display: "block" }}>
                Plastic Manufacturing
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Contact Us
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Typography color="inherit">
                admin@appliedbellcurve.com
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Follow Us
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 1, mt: 1 }}
            >
              <MuiLink href="#" color="inherit" target="_blank">
                <FacebookIcon />
              </MuiLink>
              <MuiLink href="#" color="inherit" target="_blank">
                <TwitterIcon />
              </MuiLink>
              <MuiLink
                href="https://www.linkedin.com/company/applied-bell-curve/"
                target="_blank"
                color="inherit"
              >
                <LinkedInIcon />
              </MuiLink>
              <MuiLink href="#" color="inherit" target="_blank">
                <InstagramIcon />
              </MuiLink>
            </Box>
          </Grid>
        </Grid>

        <hr
          style={{
            width: "90%",
            margin: "20px auto",
            borderColor: "white",
          }}
        />
        <Grid container spacing={2} sx={{ maxWidth: "90%", mx: "auto" }}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body2"
              color="inherit"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              &copy; 2024 Applied Bell Curve. All rights reserved.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ textAlign: { xs: "center", md: "right" } }}
          >
            <Link
              to="/privacy-policy"
              style={{
                color: "inherit",
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              Privacy Policy |{" "}
            </Link>
            <Link
              to="/terms-and-conditions"
              style={{
                color: "inherit",
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              Terms & Conditions
            </Link>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
