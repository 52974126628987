import {
  Typography,
  Toolbar,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Home from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import { useState } from "react";

const Header = () => {
  const navigate = useNavigate();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleMobileMenuClose();
  };

  const mobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#eff9fe",
        },
      }}
    >
      <MenuItem onClick={() => handleNavigate("/")}>
        <Home sx={{ mr: 1 }} /> Home
      </MenuItem>
      <MenuItem onClick={() => handleNavigate("/contactus")}>
        <CallIcon sx={{ mr: 1 }} /> Contact Us
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: { xs: "1rem", sm: "1.25rem" }, fontWeight: "bold" }}
          >
            Applied Bell Curve
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMobileMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: { xs: "1rem", sm: "1.25rem" }, fontWeight: "bold" }}
          >
            Applied Bell Curve
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => handleNavigate("/")}
              sx={{
                color: "black",
                backgroundColor: "transparent",
                textDecoration: "none",
                textTransform: "none",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <Home sx={{ mr: 1 }} />
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  fontWeight: "bold",
                }}
              >
                Home
              </Typography>
            </Button>
            <Button
              onClick={() => handleNavigate("/contactus")}
              sx={{
                color: "black",
                backgroundColor: "transparent",
                textDecoration: "none",
                textTransform: "none",
                "&:hover": { backgroundColor: "transparent" },
                ml: 2,
              }}
            >
              <CallIcon sx={{ mr: 1 }} />
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  fontWeight: "bold",
                }}
              >
                Contact Us
              </Typography>
            </Button>
          </Box>
        </Box>
      </Toolbar>
      {mobileMenu}
    </>
  );
};

export default Header;
