import React from 'react';
import { Box, Typography, ThemeProvider } from '@mui/material';
import theme  from '../Utils/themes';

const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
    <Box 
      sx={{ 
        maxWidth: '80%', 
        margin: 'auto',
        marginTop:'2%', 
        padding: '20px', 
        backgroundColor: '#f9f9f9', 
        borderRadius: '8px', 
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', 
        height: '80vh', 
        overflowY: 'auto',
        textAlign: 'left'
      }}
    >
      <Box 
        sx={{
          backgroundColor: '#063954',
          color: 'white',
          padding: '10px',
          borderRadius: '8px 8px 8px 8px', // Rounded corners on top
          textAlign: 'center'
        }}
      >
        <Typography variant="h5" gutterBottom><strong>Terms And Conditions</strong></Typography>
      </Box>
      <Box sx={{marginTop:'1%'}}>
        <Typography paragraph><strong>Last Updated: 21/08/2024</strong></Typography>
      </Box>

      <Typography variant="h6" gutterBottom><strong>1. Introduction</strong></Typography>
      <Typography paragraph>
      These Terms and Conditions ("Terms") govern your use of Applied Bell Curve's SaaS business tools for production optimization and purchase planning ("Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our Services.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>2. Use of Services</strong></Typography>
      <Typography paragraph>
        <strong>Eligibility:</strong> You must be at least 18 years old and have the legal capacity to enter into a binding agreement to use our Services.
     </Typography>
      <Typography paragraph>
        <strong>License:</strong> We grant you a limited, non-exclusive, non-transferable license to access and use our Services for your internal business purposes, subject to these Terms.
      </Typography>
      <Typography paragraph>
        <strong>Prohibited Uses:</strong> You agree not to misuse our Services, including but not limited to, using them for unlawful purposes, violating any applicable laws, infringing on our intellectual property rights, or attempting to disrupt the integrity or performance of our Services.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>3. User Accounts</strong></Typography>
      <Typography paragraph>
        <strong>Account Creation: </strong> To use our Services, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
      </Typography>
      <Typography paragraph>
        <strong>Account Termination: </strong> We reserve the right to suspend or terminate your account at our discretion if you violate these Terms or engage in conduct that harms the integrity of our Services.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>4. Fees and Payment</strong></Typography>
      <Typography paragraph>
        <strong>Subscription Fees: </strong>Our Services are provided on a subscription basis. You agree to pay all fees associated with your subscription plan. All payments are non-refundable unless otherwise stated.
      </Typography>
      <Typography paragraph>
        <strong>Billing: </strong>We will bill you in advance on a recurring basis (monthly or annually) according to your subscription plan. You are responsible for providing accurate billing information and keeping it up-to-date.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>5. Intellectual Property</strong></Typography>
      <Typography paragraph>
        <strong>Ownership: </strong>We retain all rights, title, and interest in and to our Services, including all related intellectual property rights. Your use of our Services does not grant you any ownership rights.
      </Typography>
      <Typography paragraph>
        <strong>Restrictions: </strong>You may not copy, modify, distribute, sell, or lease any part of our Services or attempt to reverse engineer or extract the source code of our software.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>6. Data Protection</strong></Typography>
      <Typography paragraph>
        <strong>Customer Data: </strong> You retain all rights to the data you input into our Services ("Customer Data"). We will process Customer Data only in accordance with our Privacy Policy and applicable data protection laws.
      </Typography>
      <Typography paragraph>
        <strong>Data Security: </strong>We implement appropriate security measures to protect your data. However, you acknowledge that no system is entirely secure, and we cannot guarantee the absolute security of your data.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>7. Limitation of Liability</strong></Typography>
      <Typography paragraph>
        To the maximum extent permitted by law, Applied Bell Curve shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use our Services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from our Services.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>8. Indemnification</strong></Typography>
      <Typography paragraph>
      You agree to indemnify and hold Applied Bell Curve, its affiliates, officers, directors, employees, and agents harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Services or your violation of these Terms.
      </Typography>

      <Typography variant="h6" gutterBottom><strong>9. Modifications to Services and Terms</strong></Typography>
      <Typography paragraph>
        We reserve the right to modify or discontinue our Services at any time, with or without notice. We may also modify these Terms from time to time. Any changes will be effective immediately upon posting on our website. Your continued use of the Services after such modifications constitute your acceptance of the revised Terms.
      </Typography>
      <Typography variant="h6" gutterBottom><strong>10. Governing Law and Dispute Resolution</strong></Typography>
      <Typography paragraph>
        These Terms are governed by and construed in accordance with the laws of Supreme Court of India. Any disputes arising out of or relating to these Terms or our Services shall be resolved exclusively in the courts of Supreme Court of India.
      </Typography>
      <Typography variant="h6" gutterBottom><strong>11. Contact Information</strong></Typography>
      <Typography paragraph>
      If you have any questions about these Terms, please contact us at:
      </Typography>
      <Typography paragraph>
        Applied Bell Curve <br/>
        admin@appliedbellcurve.com
      </Typography>
    </Box>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
