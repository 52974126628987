import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  ThemeProvider,
  Typography,
  Button,
  Grid,
  Card,
  styled,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import theme from "../../Utils/themes";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DescriptionIcon from "@mui/icons-material/Description";
import TimelineIcon from "@mui/icons-material/Timeline";
import SpeedIcon from "@mui/icons-material/Speed";
import company_image from "../../Images/company_image.jpg";

const HomeSection = () => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleLoginClick = () => {
    navigate("/login");
  };

  const StyledCard = styled(Card)(({ height }) => ({
    margin: "0% 1%",
    display: "flex",
    flexDirection: "column",
    height: height || "100%",
    justifyContent: "space-between",
    borderRadius: "7%",
    transition: "0.3s",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    "&:hover": {
      boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
      transform: "translateY(-4px)",
    },
  }));

  const ImageStyledCard = styled(StyledCard)({
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        id="home"
        className="section"
        sx={{
          mx: { xs: 1, sm: 3, md: 10 },
          mt: 0,
          pt: 0,
          pb: 0, 
          height: "70vh",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              padding: "10px",
              textAlign: "center", 
              mb: 0, 
            }}
          >
            <Box sx={{ padding: { xs: "15px", sm: "25px" } }}>
              <Typography
                variant="h2"
                fontWeight="bold"
                sx={{ color: "#063954" }}
              >
                FASTER, EASIER & BETTER DECISIONS
              </Typography>
              <Typography variant="h6">
                <br />
                Turn Insights Into Actions to Achieve Goals.
                <br />
                Empower your workforce with relevant insights and actions to
                help improve quality, productivity, and culture of the
                organisation.
              </Typography>
              <br />
              <Button
                variant="contained"
                onClick={handleLoginClick}
              >
                Get Started
              </Button>
            </Box>
          </Grid>

          {!isMobile && (
            <Grid
              container
              spacing={1} 
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ mt: 0, mb: 0 }} 
            >
              <Grid item xs={12} sm={6} md={2}>
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <NorthEastIcon style={{ color: "#063954", fontSize: 40 }} />
                </Box>
                <Box sx={{ textAlign: "center", marginBottom: "15px" }}> 
                  <AutoAwesomeIcon style={{ color: "#063954", fontSize: 40 }} />
                </Box>
                <ImageStyledCard height="250px">
                  <img src={company_image} alt="Company Image" />
                </ImageStyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={1.8}>
                <StyledCard height="220px">
                  <CardContent
                    style={{ flexGrow: 1, backgroundColor: "#063954" }}
                  >
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="h2"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      <br />
                      5+
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h7"
                      component="h5"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      Our Esteemed Clients and Partners
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <StyledCard height="140px">
                  <CardContent style={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="h4"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Total Projects
                      <NorthEastIcon
                        style={{
                          color: "#063954",
                          marginLeft: "5px",
                          marginRight: "5px",
                          fontSize: 25,
                        }}
                      />
                      8%
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="h3"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      25+
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="h6"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Increase of{" "}
                      <span style={{ color: "#063954", padding: "0 5px" }}>
                        10
                      </span>{" "}
                      this month
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={1.8}>
                <StyledCard height="220px">
                  <CardContent
                    style={{ flexGrow: 1, backgroundColor: "#ADD8E6" }}
                  >
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="h2"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <br />
                      2+
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h7"
                      component="h5"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Years of Dedicated Service
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <Box
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <DescriptionIcon style={{ color: "#063954", fontSize: 40 }} />
                </Box>
                <Box sx={{ textAlign: "center", marginBottom: "15px" }}> 
                  <TimelineIcon style={{ color: "#063954", fontSize: 40 }} />
                </Box>
                <StyledCard height="250px">
                  <CardContent
                    style={{ flexGrow: 1, backgroundColor: "#063954" }}
                  >
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="h4"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      <br />
                      <br />
                      <br />
                      <br />
                      <SpeedIcon style={{ color: "white", fontSize: 25 }} />
                      <br />
                      Achieve Optimal Efficiency and Boost Productivity
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default HomeSection;
