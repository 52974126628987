import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  ThemeProvider,
  Button,
} from "@mui/material";
import theme from "../../Utils/themes";
import { useNavigate } from "react-router-dom";

const PlanCard = ({ plan }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Card
      sx={{
        maxWidth: {xs:400,md:600},
        height: "auto",
        minHeight: 300,
        backgroundColor: "#074668",
        borderRadius: 3,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h4"
            fontWeight="bold"
            color="whitesmoke"
            sx={{ mt: 1, mb: 7, textAlign: "center" }}
          >
            {plan.title}
          </Typography>
          <Typography
            variant="body1"
            color="whitesmoke"
            sx={{ mb: 7, textAlign: "center" }}
          >
            {plan.description}
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "white",
              color: "#074668",
              fontWeight: "bold",
            }}
            onClick={() => handleNavigate("/contactus")}
          >
            Get Started
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const Plans = () => {
  const plan = [
    {
      title: "Professional",
      description:
        "Designed for greater flexibility, the solution offers advanced tools for custom tailoring your needs",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "70vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: 2,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          color="whitesmoke"
          sx={{ mb: 10,mt:2 , ml: {md:12}, textAlign: "center" }}
        >
          Tailored Plans for your
          <br />
          Manufacturing Scale
        </Typography>

        <Grid container justifyContent="center" sx={{ maxWidth: 600 }}>
          {plan.map((plan, index) => (
            <Grid item key={index} xs={12} sm={12} md={12}>
              <PlanCard plan={plan} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Plans;
