import React from "react";
import { Box, ThemeProvider } from "@mui/material";
import "typeface-raleway";
import "./landing.css";
import HomeSection from "./Home/home";
import Header from "./Header/header.js";
import ServicesList from "./services/services";
import Plans from "./Plans/plans";
import Footer from "./Footer/footer";
import theme from "../Utils/themes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ minHeight: "100vh" }}>
        <Box sx={{ backgroundColor: "#eff9fe", padding: 0, margin: 0 }}>
          <Header />
        </Box>

        <Box
          className="scroll-container"
          sx={{
            backgroundColor: "#eff9fe",
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 64px)",
            padding: 0,
            margin: 0,
          }}
        >
          <Box
            id="home-section"
            className="section"
            sx={{ flexGrow: 1, backgroundColor: "#eff9fe", padding: 0 }}
          >
            <HomeSection />
          </Box>

          <Box
            id="services-section"
            className="section"
            sx={{
              backgroundColor: "#063954",
              padding: 0, 
              margin: 0, 
            }}
          >
            <ServicesList />
          </Box>

          {/* <Box id="keybenefits-section" className="section">
            <KeyBenefits />
          </Box> */}

          <Box
            id="plans-section"
            className="section"
            sx={{
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0, 
              margin: 0, 
              flexGrow: 1,
            }}
          >
            <Plans />
          </Box>
        </Box>

        <Box sx={{ backgroundColor: "#eff9fe" }}>
          <Footer />
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
